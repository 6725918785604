import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { MonthlyPaymentCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellToutMortgage } from './constants'
import { usePages } from '../constants'

const MortgageCalculatorPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, mortgagePage } = usePages()

  const pageData = [
    {
      sectionTitle: t('MortgageCalculatorPage.subTitle', {
        defaultValue: 'What will my mortgage payments be?',
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('MortgageCalculatorPage.textContent', {
                  defaultValue:
                    'Use this mortgage payment calculator to get a better picture of what monthly payments to expect based on your house price, interest rate and down payment. You’ll also see the total amount of interest over the term of the loan.',
                }),
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('MortgageCalculatorPage.textContent2a', {
                  defaultValue:
                    'While this calculator can help you understand the costs of buying a home, all numbers provided here are estimates. Please ',
                }),
                data: {},
              },
              {
                nodeType: 'hyperlink',
                content: [
                  {
                    nodeType: 'text',
                    marks: [],
                    value: t('MortgageCalculatorPage.textContent2b', {
                      defaultValue: 'talk to a Mortgage Loan Officer',
                    }),
                    data: {},
                  },
                ],
                data: {
                  uri: `/home-lending/mortgage-team`,
                },
              },
              {
                nodeType: 'text',
                marks: [],
                value: t('MortgageCalculatorPage.textContent2c', {
                  defaultValue: ' about what your actual costs may be.',
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  const CalcTips = () => (
    <Trans i18nKey="MortgageCalculatorPage.calcTips">
      <p>
        If you select "yes" to{' '}
        <strong>
          let us estimate property taxes, insurance and private mortgage insurance (PMI)
        </strong>
        , we will use national averages to give you a rough idea.
      </p>

      <p>
        <strong>PMI</strong> is typically required if your down payment is less than 20% and insures
        the lender against losses in the event of foreclosure.
      </p>
    </Trans>
  )

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: mortgagePage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('MortgageCalculatorPage.pageTitle', {
              defaultValue: 'Mortgage Payment Calculator',
            })}
            pathname="/calculators/mortgage"
          />
          <CalculatorSectionModule
            pageTitle={t('MortgageCalculatorPage.title', {
              defaultValue: 'Mortgage Calculators',
            })}
            sections={pageData}
            calculator={<MonthlyPaymentCalculator />}
            anchorLinkSections={anchorLinkSections()}
            calcTips={<CalcTips />}
          />
          <CrossSellToutMortgage />
        </Layout>
      )}
    </StaticTemplate>
  )
}

MortgageCalculatorPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { MortgageCalculatorPage }
export default MortgageCalculatorPage
